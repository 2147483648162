import { z } from 'zod';

const sessionResponseNotificationMetaDataSchema = z.object({
  avatar: z.string().optional(),
  counterpartyFirstName: z.string(),
  counterpartyLastName: z.string(),
  sessionName: z.string(),
  sessionLocation: z.string(),
  sessionDescription: z.string(),
  sessionStart: z.string(),
  sessionEnd: z.string(),
  sessionUrl: z.string(),
  sessionId: z.string().optional(),
});

export const notificationMetaDataSchema = z.discriminatedUnion('eventType', [
  z.object({
    eventType: z.literal('relationships-individual-unread-message'),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
    relationshipUrl: z.string(),
  }),
  z.object({
    eventType: z.literal('relationships-individual-schedule-session'),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
    createSessionUrl: z.string(),
  }),
  z.object({
    eventType: z.literal('relationships-individual-upcoming-session'),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
    sessionName: z.string(),
    sessionLocation: z.string(),
    sessionDescription: z.string(),
    sessionStart: z.string(),
    sessionEnd: z.string(),
    sessionUrl: z.string(),
    sessionId: z.string().optional(),
  }),
  z.object({
    eventType: z.literal('relationships-individual-session-updated'),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
    sessionName: z.string(),
    sessionLocation: z.string(),
    sessionDescription: z.string(),
    sessionStart: z.string(),
    sessionEnd: z.string(),
    oldSessionName: z.string(),
    oldSessionLocation: z.string(),
    oldSessionDescription: z.string(),
    oldSessionStart: z.string(),
    oldSessionEnd: z.string(),
    sessionUrl: z.string(),
    sessionId: z.string().optional(),
  }),
  z.object({
    eventType: z.literal('relationships-individual-session-deleted'),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
    oldSessionName: z.string(),
    oldSessionLocation: z.string(),
    oldSessionDescription: z.string(),
    oldSessionStart: z.string(),
    oldSessionEnd: z.string(),
    sessionId: z.string().optional(),
  }),
  z.object({
    eventType: z.literal('relationships-individual-relationship-created'),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
    relationshipUrl: z.string(),
  }),
  z.object({
    eventType: z.literal(
      'relationships-individual-relationship-created-by-admin',
    ),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
    relationshipUrl: z.string(),
  }),
  z.object({
    eventType: z.literal('relationships-individual-relationship-concluded'),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
  }),
  z.object({
    eventType: z.literal(
      'relationships-individual-relationship-concluded-by-admin',
    ),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
  }),
  z.object({
    eventType: z.literal(
      'relationships-individual-relationship-shared-goal-updated',
    ),
    avatar: z.string().optional(),
    counterpartyFirstName: z.string(),
    counterpartyLastName: z.string(),
    goalCategory: z.string(),
    goalObjective: z.string(),
    manageGoalsUrl: z.string(),
    goalId: z.string().optional(),
  }),
  z
    .object({
      eventType: z.literal('relationships-individual-session-accepted'),
    })
    .merge(sessionResponseNotificationMetaDataSchema),
  z
    .object({
      eventType: z.literal(
        'relationships-individual-session-tentatively-accepted',
      ),
    })
    .merge(sessionResponseNotificationMetaDataSchema),
  z
    .object({
      eventType: z.literal('relationships-individual-session-declined'),
    })
    .merge(sessionResponseNotificationMetaDataSchema),
  z.object({
    eventType: z.literal('relationships-group-unread-message'),
    avatar: z.string().optional(),
    groupName: z.string(),
    relationshipUrl: z.string(),
  }),
  z.object({
    eventType: z.literal('relationships-group-schedule-session'),
    avatar: z.string().optional(),
    guideFirstName: z.string(),
    guideLastName: z.string(),
    groupName: z.string(),
    createSessionUrl: z.string(),
  }),
  z.object({
    eventType: z.literal('relationships-group-session-deleted'),
    avatar: z.string().optional(),
    guideFirstName: z.string(),
    guideLastName: z.string(),
    groupName: z.string(),
    oldSessionName: z.string(),
    oldSessionLocation: z.string(),
    oldSessionDescription: z.string(),
    oldSessionStart: z.string(),
    oldSessionEnd: z.string(),
    sessionUrl: z.string(),
    sessionId: z.string().optional(),
  }),
  z.object({
    eventType: z.literal('relationships-group-session-updated'),
    avatar: z.string().optional(),
    guideFirstName: z.string(),
    guideLastName: z.string(),
    groupName: z.string(),
    sessionName: z.string(),
    sessionLocation: z.string(),
    sessionDescription: z.string(),
    sessionStart: z.string(),
    sessionEnd: z.string(),
    oldSessionName: z.string(),
    oldSessionLocation: z.string(),
    oldSessionDescription: z.string(),
    oldSessionStart: z.string(),
    oldSessionEnd: z.string(),
    sessionUrl: z.string(),
    sessionId: z.string().optional(),
  }),
  z.object({
    eventType: z.literal('relationships-group-upcoming-session'),
    avatar: z.string().optional(),
    guideFirstName: z.string(),
    guideLastName: z.string(),
    groupName: z.string(),
    sessionName: z.string(),
    sessionLocation: z.string(),
    sessionDescription: z.string(),
    sessionStart: z.string(),
    sessionEnd: z.string(),
    sessionUrl: z.string(),
    sessionId: z.string().optional(),
  }),
  z.object({
    eventType: z.literal('relationships-group-relationship-guide-concluded'),
    avatar: z.string().optional(),
    guideFirstName: z.string(),
    guideLastName: z.string(),
    groupName: z.string(),
    relationshipUrl: z.string(),
  }),
  z.object({
    eventType: z.literal('relationships-group-relationship-trainee-left'),
    avatar: z.string().optional(),
    traineeFirstName: z.string(),
    traineeLastName: z.string(),
    groupName: z.string(),
    relationshipUrl: z.string(),
  }),
]);

export type KeyedNotificationMetaData = z.infer<
  typeof notificationMetaDataSchema
>;
