import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useOrganization,
  useSanityBaseLanguage,
} from '@guider-global/sanity-hooks';
import { OnboardingChecklistView } from 'views';
import { DashboardNotificationsContext } from '../DashboardWrapper';
import { getSubDomain } from '@guider-global/front-end-utils';
import { Skeleton } from '@mui/material';

export function OnboardingChecklistContainer() {
  const {
    hasMemberships,
    hasRelationships: contextHasRelationships,
    hasSessions,
    hasAttendedSessions,
    programSlug,
    relationshipId,
    sessionId,
    isGuide,
    isGroupProgram,
    isActiveGroup,
    isEmptyGroup,
    relationship,
    isLoading,
    profile,
  } = useContext(DashboardNotificationsContext);

  const hasOpenMatchingRole = profile?.roles?.length !== 0;

  const navigate = useNavigate();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });

  const hideProgramItem =
    (organization?.open_matching?.enable_open_matching &&
      hasOpenMatchingRole) ??
    false;

  const isNotEmptyGroup = !isEmptyGroup;
  const isNotActiveGroup = !isActiveGroup;
  const hasRelationships =
    isGroupProgram && isGuide ? isNotEmptyGroup : contextHasRelationships;

  const percentageComplete = hideProgramItem ? 33 : 25;

  const programStep = !hideProgramItem
    ? [
        {
          key: 'join-program',
          label:
            baseLanguage?.dashboard?.onboarding?.progress_indicator?.steps
              ?.join_a_program_label,
          percentCompletion: 25,
          isChecked: hasMemberships,
          onClick: () => navigate('/programs'),
          isCurrent:
            !hasMemberships &&
            !hasRelationships &&
            !hasSessions &&
            !hasAttendedSessions,
        },
      ]
    : [];

  const steps = [
    {
      key: 'find-match',
      label:
        baseLanguage?.dashboard?.onboarding?.progress_indicator?.steps
          ?.find_a_match_label,
      percentCompletion: percentageComplete,
      isChecked: hasRelationships,
      onClick: isGuide
        ? undefined
        : () => navigate(`/programs/${programSlug}/join/trainee/choose`),
      isCurrent:
        hasMemberships &&
        (isGroupProgram && isGuide
          ? isNotActiveGroup // group guide remains at this step until group is activated
          : !hasRelationships) &&
        !hasSessions &&
        !hasAttendedSessions,
    },
    {
      key: 'schedule-session',
      label:
        baseLanguage?.dashboard?.onboarding?.progress_indicator?.steps
          ?.schedule_session_label,
      isChecked: hasSessions,
      percentCompletion: percentageComplete,
      onClick: () =>
        navigate(`/relationships/${relationshipId}/sessions/create`),
      isCurrent:
        hasMemberships &&
        (isGroupProgram && isGuide
          ? isActiveGroup // group guide can schedule a session once group is activated
          : hasRelationships) &&
        !hasSessions &&
        !hasAttendedSessions,
    },
    {
      key: 'attend-session',
      label:
        baseLanguage?.dashboard?.onboarding?.progress_indicator?.steps
          ?.attend_session_label,
      percentCompletion: percentageComplete,
      isChecked: hasAttendedSessions,
      onClick: () =>
        navigate(`/relationships/${relationshipId}/sessions/${sessionId}`),
      isCurrent:
        hasMemberships &&
        hasRelationships &&
        hasSessions &&
        !hasAttendedSessions,
    },
  ];

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        sx={{ minHeight: '420px', borderRadius: '16px' }}
      />
    );
  }

  return (
    <OnboardingChecklistView
      heading={baseLanguage?.dashboard?.onboarding?.progress_indicator?.title}
      percentCompleteLabel={
        baseLanguage?.dashboard?.onboarding?.progress_indicator?.complete_label
      }
      progressDescription={
        baseLanguage?.dashboard?.onboarding?.progress_indicator?.complete_label
      }
      description={
        baseLanguage?.dashboard?.onboarding?.progress_indicator?.description
      }
      steps={[...programStep, ...steps]}
    />
  );
}
