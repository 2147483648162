import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { Loading } from '@guider-global/ui';
import { useModalNotifications } from 'hooks/useNotificationsModal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NotificationModalView } from 'views/dashboard/NotificationModalView';
import { NotificationCardContainer } from '../notificationCardContainer';
import { INotification } from '@guider-global/shared-types';
import { useNotifications } from 'hooks/useNotifications';
import { useAxios } from '@guider-global/redux-axios-hooks';
import { useAuth } from '@guider-global/auth-hooks';

export type NotificationModalContainerProps = {
  onClose: () => void;
};

export function NotificationModalContainer({
  onClose,
}: Readonly<NotificationModalContainerProps>) {
  const pageLimit = 10;
  const loaderRef = useRef<HTMLDivElement | null>(null);

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const {
    getModalNotifications,
    reqModalNotifications,
    getIsLoadingModalNotifications,
    getMore: reqMoreModalNotifications,
    getResponse: getModalNotificationsResponse,
  } = useModalNotifications({
    getSilently: true,
    isPaginated: true,
    pageLimit,
  });
  const modalNotifications = getModalNotifications();
  const isLoadingModalNotifications = getIsLoadingModalNotifications();
  const response = getModalNotificationsResponse();
  const shouldLoadNextPage = response?.data.data?.length === pageLimit;

  const { reqLatestNotifications } = useNotifications({
    getSilently: false,
  });
  const [viewedNotificationIds, setViewedNotificationIds] = useState<string[]>(
    [],
  );

  function handleNotificationVisible(notification: INotification) {
    setViewedNotificationIds((prevViewedNotificationIds) => {
      if (
        notification.status === 'unread' &&
        !prevViewedNotificationIds.includes(notification.id)
      ) {
        return [...prevViewedNotificationIds, notification.id];
      }

      return prevViewedNotificationIds;
    });
  }

  const { accessToken, getAccessToken } = useAuth({});

  const { requestCallback } = useAxios({
    accessToken,
    onExpiredAccessToken: getAccessToken,
    waitForAuthentication: true,
  });

  const markViewedNotificationsRead = useCallback(async () => {
    if (viewedNotificationIds.length === 0) {
      return;
    }

    await requestCallback({
      method: 'PATCH',
      url: '/notifications',
      params: { id: viewedNotificationIds },
      data: { status: 'read' },
    });

    await Promise.all([
      reqModalNotifications({
        url: '/notifications',
        params: {
          page: 1,
          pageLimit,
        },
      }),
      reqLatestNotifications(),
    ]);

    setViewedNotificationIds([]);
  }, [
    reqLatestNotifications,
    reqModalNotifications,
    requestCallback,
    viewedNotificationIds,
  ]);

  function handleClose() {
    markViewedNotificationsRead();
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
    };
    const handleObserver: IntersectionObserverCallback = (entries) => {
      const entry = entries[0];
      if (
        entry.isIntersecting &&
        shouldLoadNextPage &&
        !isLoadingModalNotifications
      ) {
        reqMoreModalNotifications({ amount: pageLimit });
      }
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [
    shouldLoadNextPage,
    isLoadingModalNotifications,
    reqMoreModalNotifications,
  ]);

  const headingText = baseLanguage?.dashboard?.notifications?.modal?.title;
  const closeButtonLabel =
    baseLanguage.globals?.common?.close_button_label ?? 'Close';

  return (
    <NotificationModalView
      open={true}
      headingText={headingText}
      closeButtonLabel={closeButtonLabel}
      onClose={handleClose}
    >
      {modalNotifications.map((notification, index, array) => {
        const isFifthLastNofication = index === array.length - 5;
        return (
          <>
            {isFifthLastNofication && (
              <div ref={loaderRef} style={{ margin: 0 }} />
            )}
            <NotificationCardContainer
              key={notification.id}
              notification={notification}
              onVisible={() => handleNotificationVisible(notification)}
            />
          </>
        );
      })}
      <Loading
        isLoading={isLoadingModalNotifications}
        color="white"
        sx={{ padding: '2%' }}
      />
    </NotificationModalView>
  );
}
