import { useProfiles, useSettings } from '@guider-global/front-end-hooks';
import {
  getLanguageFromCMS,
  getSubDomain,
  getTimeFormat,
} from '@guider-global/front-end-utils';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { ISkill, ProfileSkill } from '@guider-global/shared-types';
import {
  ButtonStack,
  DashboardCard,
  Stack,
  TextStack,
} from '@guider-global/ui';
import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/system';
import { isAfter } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { useLocalization, useRelationships } from 'hooks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IDashboardRelationshipsRow,
  ProgramMembershipEmptyTableView,
  ProgramMembershipTableView,
} from 'views';

export function OpenMatchingProfileContainer() {
  const navigate = useNavigate();
  const { palette } = useTheme();

  // Sanity Programs
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });

  // Base Language
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({
    localeCode,
  });

  // Settings
  const { settings } = useSettings({
    options: { keepPreviousData: true },
  });
  const timezone = settings?.at(0)?.timezone ?? 'Europe/London';
  const formattedTime = getTimeFormat(baseLanguage?.time_format);
  const locale = getLanguageFromCMS(baseLanguage?.language_code);

  const bio_label = baseLanguage?.dashboard?.open_matching?.bio_label;
  const skills_offered_label =
    baseLanguage?.dashboard?.open_matching?.skills_offered_label;
  const skills_sought_label =
    baseLanguage?.dashboard?.open_matching?.skills_sought_label;
  const empty_state_description =
    baseLanguage?.dashboard?.open_matching?.empty_state_description ?? '';
  const empty_state_title =
    baseLanguage?.dashboard?.open_matching?.empty_state_title;
  const user_name =
    baseLanguage?.dashboard?.program_memberships?.with_program_memberships
      ?.relationships_table?.table_headers?.user_name;
  const next_session =
    baseLanguage?.dashboard?.program_memberships?.with_program_memberships
      ?.relationships_table?.table_headers?.next_session;
  const number_of_sessions =
    baseLanguage?.dashboard?.program_memberships?.with_program_memberships
      ?.relationships_table?.table_headers?.number_of_sessions;

  const no_session_placeholder =
    baseLanguage?.dashboard?.program_memberships?.with_program_memberships
      ?.relationships_table?.no_session_placeholder;

  // Profile

  const { profiles, isLoadingProfiles } = useProfiles({});

  const profile = profiles?.at(0);

  // Relationships

  const { relationships: getRelationships, isLoadingRelationships } =
    useRelationships({});
  const relationships = getRelationships();

  // Profile

  const profileData = useMemo(() => {
    if (!profile || !organization?.open_matching?.enable_open_matching) return;
    const roles = profile.roles;

    const guideRole =
      organization?.open_matching?.program_type?.program_type_text?.common
        ?.guide?.singular ?? 'Mentor';

    const traineeRole =
      organization?.open_matching?.program_type?.program_type_text?.common
        ?.trainee?.singular ?? 'Mentee';

    const allSkills = [
      ...(organization?.open_matching?.skills?.soft_skills ?? []),
      ...(organization?.open_matching?.skills?.hard_skills ?? []),
    ] as ProfileSkill[];

    const traineeSkillsLabels = (profile?.skills as ISkill[])
      ?.filter((skill) => skill.sought)
      .map((skill) => {
        const label =
          allSkills.find((s) => s.slug === skill.fieldSlug)?.label ?? undefined;
        return label;
      })
      .join(', ');

    const guideSkillsLabels = (profile?.skills as ISkill[])
      ?.filter((skill) => skill.offered)
      .map((skill) => {
        const label =
          allSkills.find((s) => s.slug === skill.fieldSlug)?.label ?? undefined;

        return label;
      })
      .join(', ');

    const traineeSkills = {
      label: skills_sought_label,
      value: traineeSkillsLabels,
    };

    const guideSkills = {
      label: skills_offered_label,
      value: guideSkillsLabels,
    };

    const bio = profile?.bio;

    const activeOpenMatchingRelationships: IDashboardRelationshipsRow[] =
      relationships
        .filter(
          (relationship) =>
            !relationship.programSlug && !relationship.isConcluded,
        )
        .flatMap((relationship) => {
          const matchProfile = relationship.traineeProfiles?.find(
            (p) => p.id === profile.id,
          )
            ? relationship?.guideProfiles?.at(0)
            : relationship?.traineeProfiles?.at(0);

          const matchUser = relationship.users?.find(
            (p) => p.id !== profile.userId,
          );

          const role = relationship.traineeProfiles?.find(
            (p) => p.id === profile.id,
          )
            ? 'guide'
            : 'trainee';

          const sessions = relationship.sessions ?? [];

          const pastSessions =
            sessions.length > 0
              ? sessions.slice().filter((session) => {
                  const sessionStart = session?.start
                    ? utcToZonedTime(new Date(session.start), timezone)
                    : null;
                  const currentZonedDate = utcToZonedTime(new Date(), timezone);
                  return (
                    sessionStart && !isAfter(sessionStart, currentZonedDate)
                  );
                })
              : [];

          const upcomingSessions =
            sessions.length > 0
              ? sessions
                  .slice()
                  .filter((session) => {
                    const sessionStart = session?.start
                      ? utcToZonedTime(new Date(session.start), timezone)
                      : null;
                    return (
                      !session.isArchived &&
                      sessionStart &&
                      isAfter(
                        sessionStart,
                        utcToZonedTime(new Date(), timezone),
                      )
                    );
                  })
                  .sort((a, b) => {
                    const aStart = a.start
                      ? utcToZonedTime(new Date(a.start), timezone)
                      : null;
                    const bStart = b.start
                      ? utcToZonedTime(new Date(b.start), timezone)
                      : null;
                    return aStart && bStart && aStart < bStart ? -1 : 1;
                  })
              : [];

          const nextSessionLabel =
            upcomingSessions.length === 0
              ? no_session_placeholder
              : formatInTimeZone(
                  new Date(upcomingSessions?.at(0)?.start ?? ''),
                  timezone,
                  `EEE, do MMM ${formattedTime}`,
                  { locale },
                );

          const color =
            upcomingSessions.length === 0
              ? palette.error.main
              : palette.success.main;

          return {
            name: matchProfile?.displayName ?? '',
            profilePicture: matchProfile?.picture,
            userPicture: matchUser?.picture,
            description:
              organization?.open_matching?.program_type?.program_type_text
                .common?.[role]?.singular,
            numberOfSessions: pastSessions.length.toString(),
            nextSessionLabel: nextSessionLabel,
            nextSessionColor: color,
            onClick: () => navigate(`../relationships/${relationship.id}`),
            createdAt: relationship.createdAt,
          };
        });

    const rows = activeOpenMatchingRelationships.sort((a, b) => {
      const dateA = a.createdAt ? (new Date(a.createdAt) as any) : 0;
      const dateB = b.createdAt ? (new Date(b.createdAt) as any) : 0;
      return dateB - dateA;
    });

    return {
      guideSkills,
      traineeSkills,
      bio,
      roles,
      rows,
      guideRole,
      traineeRole,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, organization, profile, relationships]);

  if (!organization?.open_matching?.enable_open_matching) return <></>;

  if (!profile) return <></>;

  if (profile.roles?.length === 0) return <></>;

  const fields =
    [
      profileData?.roles?.includes('guide') && profileData.guideSkills.value
        ? profileData.guideSkills
        : undefined,
      profileData?.roles?.includes('trainee') && profileData.traineeSkills.value
        ? profileData.traineeSkills
        : undefined,
      profileData?.bio
        ? { label: bio_label, value: profileData?.bio }
        : undefined,
    ] ?? [];

  function hideMatchButton() {
    if (!profileData?.roles?.includes('trainee')) {
      return true;
    } else if (
      profile?.skills?.length === 0 ||
      profile?.goalCategories?.length === 0
    ) {
      return true;
    }

    return false;
  }

  if (isLoadingProfiles || isLoadingRelationships()) {
    return (
      <DashboardCard
        width={{ xs: '100%', md: '1136px' }}
        title={organization?.open_matching?.details?.title}
      >
        <Skeleton
          variant="rounded"
          sx={{ minHeight: '420px', borderRadius: '16px' }}
        />
      </DashboardCard>
    );
  }

  return (
    <DashboardCard
      width={{ xs: '100%', md: '1136px' }}
      title={
        <TextStack
          size="medium"
          heading={organization?.open_matching?.details?.title}
          chips={[
            {
              variant: 'contained',
              label: profileData?.guideRole ?? 'Mentor',
              color: 'dark',
              position: 'right',
              hidden: profileData?.roles?.includes('guide') ? false : true,
              sx: {
                height: 'fit-content',
              },
            },
            {
              variant: 'contained',
              label: profileData?.traineeRole ?? 'Mentee',
              color: 'info',
              position: 'right',
              hidden: profileData?.roles?.includes('trainee') ? false : true,
              sx: {
                height: 'fit-content',
              },
            },
          ]}
        />
      }
      backgroundColor="white"
    >
      <Stack direction={{ xs: 'column', lg: 'row' }} gap={3}>
        <Stack
          direction={'column'}
          justifyContent={'space-between'}
          sx={{ textAlign: 'left' }}
          width={{ xs: '100%', md: '35%' }}
        >
          <Stack direction={'column'}>
            {fields?.map((field, index) => {
              if (!field) return <></>;
              return (
                <TextStack
                  spacing={1.5}
                  key={`membership-field-${index}`}
                  size="xs"
                  heading={field.label}
                  subtitles={[field.value]}
                  sx={{ wordBreak: 'break-word !important' }}
                />
              );
            })}
          </Stack>

          <ButtonStack
            mt={1.5}
            spacing={0}
            gap={1.5}
            direction={{ xs: 'column' }}
            width={'100%'}
            buttons={[
              {
                key: '1',
                variant: 'outlined',
                label: baseLanguage?.globals?.common?.edit_profile_button_label,
                color: 'info',
                onClick: () => navigate('../profile/bio'),
              },
              {
                key: '2',
                variant: 'contained',
                label: baseLanguage?.globals?.common?.find_a_match_button_label,
                color: 'info',
                onClick: () => navigate('../profile/matches/guide'),
                hidden: hideMatchButton(),
              },
            ]}
          />
        </Stack>
        <Stack direction={'column'} width={{ xs: '100%', md: '65%' }}>
          {profileData && profileData?.rows.length !== 0 ? (
            <ProgramMembershipTableView
              rows={profileData.rows as IDashboardRelationshipsRow[]}
              headers={{
                name: user_name,
                numberOfSessions: number_of_sessions,
                nextSession: next_session,
              }}
            />
          ) : (
            <ProgramMembershipEmptyTableView
              heading={empty_state_title}
              descriptions={[empty_state_description]}
              leftButton={undefined}
              rightButton={undefined}
            />
          )}
        </Stack>
      </Stack>
    </DashboardCard>
  );
}
