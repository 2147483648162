import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { useProfiles, useUsers } from '@guider-global/front-end-hooks';
import { buildSanityImageUrl, useSettings } from '@guider-global/sanity-hooks';
import { avatarSrc } from '@guider-global/ui';
import { useLeaderboardPositions } from 'hooks';

export function useProgressLeaderboardResources() {
  const { settings } = useSettings({});
  const defaultAvatarSrc = buildSanityImageUrl({
    source: settings?.static_media?.relationships?.default_user_avatar ?? '',
  });

  // Users
  const { users, isLoadingUsers } = useUsers({
    options: { keepPreviousData: true },
  });
  const userSelf = users?.at(0);
  const userIdSelf = userSelf?.id;

  // Profiles
  const { getProfileImage } = useProfileImage();
  const { profiles, isLoadingProfiles } = useProfiles({});

  const ownProfile = userIdSelf
    ? profiles?.find((profile) => profile.userId === userIdSelf)
    : undefined;
  const ownProfileId = ownProfile?.id;

  // Leaderboard positions
  const { leaderboardPositions, isLoadingLeaderboardPositions } =
    useLeaderboardPositions({});

  const ownLeaderboardPosition = ownProfileId
    ? leaderboardPositions?.find(
        (leaderboardPosition) => leaderboardPosition.id === ownProfileId,
      )
    : undefined;

  const ownLeaderboardPositionIndex = ownProfileId
    ? leaderboardPositions?.findIndex(
        (leaderboardPosition) => leaderboardPosition.id === ownProfileId,
      )
    : undefined;

  const sessionCount = ownLeaderboardPosition?.sessionCount ?? 0;
  const relationshipCount = ownLeaderboardPosition?.relationshipCount ?? 0;
  const goalCount = ownLeaderboardPosition?.goalCount ?? 0;
  const contributions = ownLeaderboardPosition?.contributions ?? 0;

  const leaderboardRows = leaderboardPositions?.map((leaderboardPosition) => {
    const { id, displayName, contributions, avatarSrc } = leaderboardPosition;
    const position = leaderboardPositions.findIndex((pos) => pos.id === id) + 1;
    const avatarImage = getProfileImage({
      profilePicture: avatarSrc,
      userPicture: defaultAvatarSrc,
    });

    return {
      position,
      avatarSrc: avatarImage,
      displayName,
      score: contributions,
    };
  });

  const isLoadingProgressLeaderboardResources =
    isLoadingUsers || isLoadingProfiles || isLoadingLeaderboardPositions;

  return {
    userIdSelf,
    ownProfileId,
    leaderboardPositions,
    ownLeaderboardPosition,
    ownLeaderboardPositionIndex,
    sessionCount,
    relationshipCount,
    goalCount,
    contributions,
    avatarSrc,
    leaderboardRows,
    isLoadingProgressLeaderboardResources,
  };
}

export type ProgressLeaderboardResources = ReturnType<
  typeof useProgressLeaderboardResources
>;
