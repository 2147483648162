import { useNotifications } from 'hooks/useNotifications';
import { useModalNotifications } from 'hooks/useNotificationsModal';
import { useEffect } from 'react';
import { NotificationCardContainer } from '../notificationCardContainer';
import { Stack, Image, TextStack, ButtonStack } from '@guider-global/ui';
import NoNotifications from 'assets/empty_notifications.svg';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useNavigate } from 'react-router-dom';

export function NotificationHighlightsContainer() {
  const navigate = useNavigate();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  useModalNotifications({
    getSilently: true,
    isPaginated: true,
    pageLimit: 10,
  });

  const { getNotifications, reqLatestNotifications } = useNotifications({
    getSilently: false,
  });
  const notifications = getNotifications();

  useEffect(() => {
    reqLatestNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (notifications.length === 0) {
    return (
      <Stack direction={'column'} alignItems={'center'} p={3} pb={6} gap={1}>
        <Image src={NoNotifications} height={'160px'} width={'240px'} />
        <TextStack
          spacing={1}
          size="medium"
          heading={baseLanguage?.dashboard?.notifications?.empty_state?.title}
          align="center"
          subtitles={[
            {
              text: baseLanguage?.dashboard?.notifications?.empty_state
                ?.description,
              textAlign: 'center',
              color: 'text.secondary',
            },
          ]}
        />
        <ButtonStack
          width={{
            xs: '100%',
            md: '70%',
          }}
          direction={{ xs: 'column', md: 'row' }}
          spacing={1.5}
          buttons={[
            {
              key: 'empty-notifications-btn-1',
              variant: 'contained',
              label:
                baseLanguage?.dashboard?.notifications?.empty_state?.view_goals,
              onClick: () => navigate('/relationships'),
              color: 'info',
              sx: { px: 3 },
            },
            {
              key: 'empty-notifications-btn-2',
              variant: 'outlined',
              label:
                baseLanguage?.dashboard?.notifications?.empty_state
                  ?.explore_learning_hub,
              onClick: () => navigate('/learn'),
              color: 'info',
              sx: {
                px: 3,
              },
            },
          ]}
        />
      </Stack>
    );
  }

  return notifications.map((notification) => (
    <NotificationCardContainer
      key={notification.id}
      notification={notification}
    />
  ));
}
