import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganization,
  useSanityBaseLanguage,
} from '@guider-global/sanity-hooks';
import { DashboardCard, ResourceCard, Stack, Image } from '@guider-global/ui';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import Arrow from 'assets/arrow-icon.svg';

export function DashboardResourcesContainer() {
  //organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
  });
  const learningHubLayoutItems = organization?.learning_hub?.layout;
  const organizationTopResources = organization?.dashboard_page?.top_resources;

  //baseLanguage
  const { getBaseLanguage } = useSanityBaseLanguage({ getSilently: false });
  const baseLanguage = getBaseLanguage();

  const buttonLabels = baseLanguage?.dashboard?.resources?.content_type_actions;
  const resourceTypeLabels = baseLanguage?.learning_hub?.content_types;

  const navigate = useNavigate();

  const resources = useMemo(() => {
    if (
      organizationTopResources?.content_selection_type === 'custom' &&
      organizationTopResources?.content_selection
    ) {
      return organizationTopResources?.content_selection;
    } else {
      if (!learningHubLayoutItems) return [];
      return learningHubLayoutItems
        .flatMap((learningHubLayoutItem) => {
          if (learningHubLayoutItem._type === 'learningHubCallout') {
            return [];
          } else {
            return learningHubLayoutItem?.tile_group_content_group?.content
              ?.content_references;
          }
        })
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);
    }
  }, [organizationTopResources, learningHubLayoutItems]);

  const resourcesData = useMemo(
    () =>
      resources.map((resource) => {
        const slug = resource.metadata.slug.current;
        const imageSrc = buildSanityImageUrl({
          source: resource?.content?.image?.asset,
          width: 800,
        });
        let onClick = () => navigate(`/learn/${slug}`);
        const title = resource?.content?.title ?? '...';
        const description = resource?.content?.excerpt;
        const resourceType = resource?.metadata?.type;
        let buttonLabel, mediaLabel;
        if (resourceType === 'article') {
          buttonLabel = buttonLabels?.read_article_button_label;
          mediaLabel = resourceTypeLabels?.article;
        }
        if (resourceType === 'audio') {
          buttonLabel = buttonLabels?.listen_to_audio_button_label;
          mediaLabel = resourceTypeLabels?.audio;
        }
        if (resourceType === 'link') {
          buttonLabel = buttonLabels?.visit_website_button_label;
          mediaLabel = resourceTypeLabels?.external_link;
          onClick = () =>
            window.open(
              resource?.content?.link,
              '_blank',
              'noopener,noreferrer',
            );
        }
        if (resourceType === 'pdf') {
          buttonLabel = buttonLabels?.read_pdf_button_label;
          mediaLabel = resourceTypeLabels?.embedded_pdf;
        }
        if (resourceType === 'video') {
          buttonLabel = buttonLabels?.watch_video_button_label;
          mediaLabel = resource?.content?.video_duration
            ? `${resourceTypeLabels?.video} • ${resource?.content?.video_duration}`
            : resourceTypeLabels?.video;
        }
        return {
          slug,
          title,
          description,
          buttonLabel,
          mediaLabel,
          imageSrc,
          onClick,
        };
      }),
    [
      buttonLabels?.listen_to_audio_button_label,
      buttonLabels?.read_article_button_label,
      buttonLabels?.read_pdf_button_label,
      buttonLabels?.visit_website_button_label,
      buttonLabels?.watch_video_button_label,
      navigate,
      resourceTypeLabels?.article,
      resourceTypeLabels?.audio,
      resourceTypeLabels?.embedded_pdf,
      resourceTypeLabels?.external_link,
      resourceTypeLabels?.video,
      resources,
    ],
  );

  return (
    <DashboardCard
      width={{ xs: '100%', md: '1136px' }}
      title={baseLanguage.dashboard?.resources?.title}
      cta={{
        label:
          baseLanguage.dashboard?.resources?.view_learning_hub_button_label,
        icon: <Image src={Arrow} height={'20px'} width={'20px'} />,
        onClick: () => navigate('/learn'),
      }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }}>
        {resourcesData.map((resource) => (
          <ResourceCard
            key={resource.slug}
            imageSrc={resource.imageSrc}
            title={resource.title}
            description={resource.description}
            mediaLabel={resource.mediaLabel}
            buttons={[
              {
                label: resource.buttonLabel,
                variant: 'contained',
                color: 'info',
                key: `resource-button-${resource.slug}`,
                onClick: resource.onClick,
              },
            ]}
            sx={{
              width: { xs: '100%', md: '33%' },
              border: 'none',
            }}
          />
        ))}
      </Stack>
    </DashboardCard>
  );
}
