import { useContext } from 'react';
import { DashboardNotificationsContext } from '../DashboardWrapper';
import { NotificationHighlightsContainer } from '../NotificationHighlightsContainer';
import { Skeleton } from '@mui/material';
import { SelectAMatchContainer } from '../SelectAMatchContainer';
import { ScheduleSessionContainer } from '../ScheduleSessionContainer';
import { OnboardingProgramContainer } from '../OnboardingProgramContainer';

export function NotificationsContainer() {
  const {
    hasMemberships,
    hasRelationships,
    hasSessions,
    hasAttendedSessions,
    hasCompletedOnboarding,
    isLoading,
    isGroupProgram,
    isActiveGroup,
    hasOpenMatchingRelationship,
    profile,
  } = useContext(DashboardNotificationsContext);

  const hasOpenMatchingRole = profile?.roles?.length !== 0;

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        sx={{ minHeight: '420px', borderRadius: '16px' }}
      />
    );
  }

  if (hasCompletedOnboarding) {
    return <NotificationHighlightsContainer />;
  }

  if (!hasMemberships && !hasOpenMatchingRelationship && !hasOpenMatchingRole) {
    return <OnboardingProgramContainer />;
  }

  if (!hasRelationships || (isGroupProgram && !isActiveGroup)) {
    return <SelectAMatchContainer />;
  }

  if (!hasSessions || (isGroupProgram && !isActiveGroup && !hasSessions)) {
    return <ScheduleSessionContainer />;
  }

  if (hasCompletedOnboarding || !hasAttendedSessions) {
    return <NotificationHighlightsContainer />;
  }
}
