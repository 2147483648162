import { PropsWithChildren, createContext, useMemo } from 'react';
import {
  useLocalization,
  useMemberships,
  useProfiles,
  useRelationships,
  useSessions,
} from 'hooks';
import { compareDesc } from 'date-fns';
import {
  EProgramVariation,
  IMembership,
  IProfile,
  IRelationship,
  Program,
} from '@guider-global/shared-types';
import { theme } from '@guider-global/ui';
import {
  useBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { Stack, ThemeProvider, useTheme } from '@mui/system';
import { deepMerge } from 'utils';
import { Background } from 'components';
import { getSubDomain } from '@guider-global/front-end-utils';

export interface DashboardNotificationsContextProps {
  membership?: IMembership;
  relationship?: IRelationship;
  profile?: IProfile;
  hasMemberships: boolean;
  hasRelationships: boolean;
  hasSessions: boolean;
  hasAttendedSessions: boolean;
  hasCompletedOnboarding: boolean;
  program?: Program;
  programSlug: string;
  programName: string;
  relationshipId: string;
  sessionId: string;
  isGuide: boolean;
  isGroupProgram: boolean;
  isHiddenGroup: boolean;
  isActiveGroup: boolean;
  isEmptyGroup: boolean;
  isLoading: boolean;
  hasOpenMatchingRelationship: boolean;
}

export const DashboardNotificationsContext =
  createContext<DashboardNotificationsContextProps>({
    membership: undefined,
    relationship: undefined,
    hasMemberships: false,
    hasRelationships: false,
    hasSessions: false,
    hasAttendedSessions: false,
    hasCompletedOnboarding: false,
    program: undefined,
    programSlug: '',
    programName: '',
    relationshipId: '',
    sessionId: '',
    isGuide: false,
    isGroupProgram: false,
    isHiddenGroup: false,
    isActiveGroup: false,
    isEmptyGroup: false,
    isLoading: false,
    hasOpenMatchingRelationship: false,
  });

export function DashboardWrapper({ children }: Readonly<PropsWithChildren>) {
  const { getProgram } = useSanityOrganizationPrograms({});
  const organizationTheme = useTheme();

  const { getProfiles, isLoadingProfiles } = useProfiles({
    getSilently: false,
  });
  const profile = getProfiles().at(0);

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { isLoadingBaseLanguage } = useBaseLanguage({ localeCode });

  const {
    memberships: getMemberships,
    isLoadingMemberships: getIsLoadingMemberships,
  } = useMemberships({ getSilently: false });
  const isLoadingMemberships = getIsLoadingMemberships();
  const memberships = getMemberships();
  const [membership] = memberships;
  const hasMemberships = memberships.length > 0;
  const programSlug = membership?.programSlug ?? '';
  const program = getProgram(programSlug);
  const programName = program?.metadata.program_name ?? '';

  const isGuide = membership?.role === 'guide';

  const {
    relationships: getRelationships,
    isLoadingRelationships: getIsLoadingRelationships,
  } = useRelationships({ getSilently: false });
  const isLoadingRelationships = getIsLoadingRelationships();
  const relationships = getRelationships();
  const activeRelationships = relationships.filter(
    (relationship) => !relationship.isConcluded,
  );
  const [relationship] = activeRelationships;
  const relationshipId = relationship?.id ?? '';
  const hasRelationships = activeRelationships.length > 0;

  const hasOpenMatchingRelationship =
    hasRelationships && relationship.programSlug === undefined;

  const isGroupProgram =
    program?.program_details?.program_variation === EProgramVariation.Group;
  const isHiddenGroup = membership?.isPublished === false;
  const isActiveGroup = isHiddenGroup; // if group is hidden, it means it bas been activated by the guide
  const isEmptyGroup = relationship?.traineeProfiles?.length === 0;

  const { sessions = [], isLoadingSessions } = useSessions({});
  const session = sessions.at(0);
  const hasSessions = sessions.length > 0;
  const attendedSessions = sessions.filter(
    (session) => compareDesc(new Date(session.end), new Date()) === 1,
  );
  const hasAttendedSessions = attendedSessions.length > 0;
  const sessionId = session?.id ?? '';

  const hasCompletedOnboarding = hasAttendedSessions; // if user has attended a session, it means they have completed onboarding

  const isLoading =
    isLoadingMemberships ||
    isLoadingRelationships ||
    isLoadingSessions ||
    isLoadingProfiles() ||
    isLoadingBaseLanguage;

  const value = useMemo(
    () => ({
      membership,
      relationship,
      hasMemberships,
      hasRelationships,
      hasSessions,
      hasAttendedSessions,
      hasCompletedOnboarding,
      program,
      programSlug,
      programName,
      relationshipId,
      sessionId,
      isGuide,
      isGroupProgram,
      isHiddenGroup,
      isActiveGroup,
      isEmptyGroup,
      isLoading,
      hasOpenMatchingRelationship,
      profile,
    }),
    [
      membership,
      relationship,
      hasMemberships,
      hasRelationships,
      hasSessions,
      hasAttendedSessions,
      hasCompletedOnboarding,
      program,
      programSlug,
      programName,
      relationshipId,
      sessionId,
      isGuide,
      isGroupProgram,
      isHiddenGroup,
      isActiveGroup,
      isEmptyGroup,
      isLoading,
      hasOpenMatchingRelationship,
      profile,
    ],
  );

  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
      <DashboardNotificationsContext.Provider value={value}>
        <Stack
          direction={'column'}
          py={{ xs: 3, md: 6 }}
          px={{ xs: 3, md: 12 }}
          gap={3}
          spacing={0}
          width={'100%'}
          alignItems={'center'}
          sx={{
            backgroundColor: '#F9FAFB',
          }}
        >
          <Background />
          {children}
        </Stack>
      </DashboardNotificationsContext.Provider>
    </ThemeProvider>
  );
}
