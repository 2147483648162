import { isValidDate, useIsSafariOrIOS } from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { IRelationship } from '@guider-global/shared-types';
import { GroupAvatar, SurveyModal } from '@guider-global/ui';
import { SurveyAvatar } from 'components';
import { SurveyForm } from 'forms';
import {
  interpolate,
  isSurveyFieldForGuide,
  isSurveyFieldForTrainee,
} from 'functions';
import {
  useRelationships,
  useSurveyResources,
  useSurveys,
  useUsers,
} from 'hooks';
import { useContext, useEffect, useState } from 'react';
import { SurveyModalsContext } from '../SurveyModalsContext';
import {
  getFriendlyDateFormat,
  getLanguageFromCMS,
  getTimeFormat,
} from '@guider-global/front-end-utils';
import { format } from 'date-fns';

export function PostRelationshipSurveyModalContainer() {
  const { surveyType, setSurveyType, isSurveySubmitted } =
    useContext(SurveyModalsContext);
  const IsSafariOrIOS = useIsSafariOrIOS();

  // Local state
  const [concludedRelationship, setConcludedRelationship] =
    useState<IRelationship>();

  // Sanity organization programs
  const { getPrograms, isLoadingSanityPrograms: getIsLoadingSanityPrograms } =
    useSanityOrganizationPrograms({});
  const isLoadingSanityPrograms = getIsLoadingSanityPrograms();
  const programs = getPrograms();

  // Users
  const { users: getUsers, isLoadingUsers: getIsLoadingUsers } = useUsers({});
  const isLoadingUsers = getIsLoadingUsers();
  const [user] = getUsers();

  // Time and date format
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const locale = getLanguageFromCMS(baseLanguage.language_code);
  const FormattedTime = getTimeFormat(baseLanguage.time_format);
  const FormattedDate = getFriendlyDateFormat(baseLanguage.date_format);

  // Relationships
  const {
    relationships: getRelationships,
    isLoadingRelationships: getIsLoadingRelationships,
  } = useRelationships({});
  const isLoadingRelationships = getIsLoadingRelationships();
  const relationships = getRelationships();

  // Surveys
  const { surveys, isLoadingSurveys } = useSurveys({});

  const isLoadingPostRelationshipSurveyResources =
    isLoadingSanityPrograms ||
    isLoadingRelationships ||
    isLoadingUsers ||
    isLoadingSurveys;

  useEffect(() => {
    if (isLoadingPostRelationshipSurveyResources) {
      return;
    }

    const concludedRelationshipWithoutSurvey = relationships.find(
      (relationship) => {
        const isGuide = relationship.guideProfiles?.at(0)?.userId === user?.id;
        const sessionCount = relationship.sessions?.length ?? 0;

        const program = programs.find(
          (program) => program.metadata.id.current === relationship.programSlug,
        );
        const survey = program?.surveys?.post_relationship_survey;
        const surveyFields = survey?.fields ?? [];
        const surveyFieldsByRole = surveyFields.filter((surveyField) =>
          isGuide
            ? isSurveyFieldForGuide(surveyField, sessionCount)
            : isSurveyFieldForTrainee(surveyField, sessionCount),
        );
        const surveyStartDate = survey?.start_date;

        const isRelationshipConcludedAfterSurveyStartDate =
          surveyStartDate &&
          isValidDate(surveyStartDate) &&
          relationship.concludedAt &&
          new Date(relationship.concludedAt) > new Date(surveyStartDate);

        const hasNotProvidedRelationshipFeedback = !surveys?.find(
          (survey) =>
            survey.type === 'post-relationship' &&
            survey.relationship === relationship.id,
        );

        return (
          survey?.enabled &&
          surveyFieldsByRole.length &&
          relationship.isConcluded &&
          isRelationshipConcludedAfterSurveyStartDate &&
          hasNotProvidedRelationshipFeedback
        );
      },
    );

    setConcludedRelationship(concludedRelationshipWithoutSurvey);
  }, [
    isLoadingPostRelationshipSurveyResources,
    relationships,
    user?.id,
    programs,
    surveys,
  ]);

  useEffect(() => {
    if (
      !isLoadingSurveys &&
      !isSurveySubmitted &&
      !surveyType &&
      concludedRelationship
    ) {
      setSurveyType('post-relationship');
    }
  }, [
    isLoadingSurveys,
    isSurveySubmitted,
    surveyType,
    concludedRelationship,
    setSurveyType,
  ]);

  const {
    title,
    description,
    heading,
    subheading,
    profile,
    surveyFields,
    isGuide,
    isGroupProgram,
    isSurveyEnabled,
    isOnGuiderVideoPage,
  } = useSurveyResources({
    surveyType: 'post_relationship_survey',
    relationship: concludedRelationship,
    user,
  });

  if (
    isSurveySubmitted ||
    surveyType !== 'post-relationship' ||
    !surveyType ||
    !concludedRelationship?.programSlug ||
    !isSurveyEnabled ||
    !concludedRelationship ||
    !surveyFields.length ||
    isOnGuiderVideoPage
  ) {
    return <></>;
  }
  const concludedAt = concludedRelationship?.concludedAt;

  const time = concludedAt
    ? format(new Date(concludedAt), FormattedTime, { locale })
    : '';

  const date = concludedAt
    ? format(new Date(concludedAt), FormattedDate, { locale })
    : '';

  const interpolatedDescription = interpolate(description, {
    time,
    date,
  });

  return (
    <SurveyModal
      open
      title={title}
      description={interpolatedDescription}
      heading={heading}
      subheading={subheading}
      Avatar={
        !IsSafariOrIOS ? (
          isGroupProgram ? (
            <GroupAvatar width={64} height={64} size="48px" />
          ) : (
            <SurveyAvatar
              relationshipId={concludedRelationship.id}
              isGuide={isGuide}
            />
          )
        ) : (
          <></>
        )
      }
    >
      <SurveyForm
        title={title}
        programSlug={concludedRelationship.programSlug}
        profile={profile?.id}
        type={'post-relationship'}
        relationship={concludedRelationship.id}
        surveyFields={surveyFields}
        isGuide={isGuide}
      />
    </SurveyModal>
  );
}
